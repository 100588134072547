import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { TableList } from "../../../../components";
import styles from './Caets.module.scss';
import common from '../../../../common';

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Caets() {
    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pageid = queryParams.get('pageid');

    const [selectedData, setSelectedData] = useState([]);


    const [data1 , setData1] = useState([]);
    const [data2 , setData2] = useState([]);
    const [data3 , setData3] = useState([]);
    const [data4 , setData4] = useState([]);
    const [data5 , setData5] = useState([]);
    const [data6 , setData6] = useState([]);
    const [data7 , setData7] = useState([]);


    const categoryData = ['27','28','145','146','263','264','265'];

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_document/out/get_table`, {categoryData:categoryData}, {
                withCredentials: true,
            });


            if (response.data.result === 't') {
                const allData = response.data.data;

                const newData1 = [];
                const newData2 = [];
                const newData3 = [];
                const newData4 = [];
                const newData5 = [];
                const newData6 = [];
                const newData7 = [];
    
                allData.forEach(item => {
                    switch (item.documentCategoryIDX) {
                        case 27:
                            newData1.push(item);
                            break;
                        case 28:
                            newData2.push(item);
                            break;
                        case 145:
                            newData3.push(item);
                            break;
                        case 146:
                            newData4.push(item);
                            break;
                        case 263:
                            newData5.push(item);
                            break;
                        case 264:
                            newData6.push(item);
                            break;
                        case 265:
                            newData7.push(item);
                            break;
                        default:
                            console.warn(`Unexpected category: ${item.documentCategoryIDX}`);
                    }
                });
    
                setData1(newData1);
                setData2(newData2);
                setData3(newData3);
                setData4(newData4);
                setData5(newData5);
                setData6(newData6);
                setData7(newData7);
            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = useMemo(() => [
        { title: 'Title', dataIndex: 'title', className: 'p_td_title p_td_w_85' },
        { title: 'Date', dataIndex: 'createTime', className: 'p_td_date' },
    ], []);
    const pageSize = 10;
    const categoryOptions = useMemo(() => [
        { label: 'Entire', value: '전체' },
        { label: 'Title', value: 'title' },
        { label: 'Content', value: '' },
    ], []);

    const [returnTableList, setReturnTableList] = useState(null);

    useEffect(() => {
        let selectedData = [];
        switch (pageid) {
            case '1':
                selectedData = data1;
                break;
            case '2':
                selectedData = data2;
                break;
            case '3':
                selectedData = data3;
                break;
            case '4':
                selectedData = data4;
                break;
            case '5':
                selectedData = data5;
                break;
            case '6':
                selectedData = data6;
                break;
            case '7':
                selectedData = data7;
                break;
            default:
                selectedData = data1;
        }
        setSelectedData(selectedData);
        setReturnTableList(
            <TableList
                key={pageid}
                data={selectedData}
                columns={columns}
                pageSize={pageSize}
                categoryOptions={categoryOptions}
                type="Caets"
            />
        );
    }, [pageid, columns, data1, data2, data3, data4, data5, pageSize, categoryOptions]);

    // =================================

    const overviewRef = useRef(null);
    const forumHistoryRef = useRef(null);

    const handleButtonClick = (section) => {
        if (section === 'overview' && overviewRef.current) {
            window.scrollTo({
                top: overviewRef.current.offsetTop - 150,
                behavior: 'smooth',
            });
        } else if (section === 'forumHistory' && forumHistoryRef.current) {
            window.scrollTo({
                top: forumHistoryRef.current.offsetTop - 150,
                behavior: 'smooth',
            });
        }
    };

    return (
        <>
            <div className="components_wrap">
                <div className={styles.tab_btn_box} ref={overviewRef}>
                    <button
                        className={`${styles.tab_btn} ${styles.active}`}
                        onClick={() => handleButtonClick('overview')}
                    >
                        Overview
                    </button>
                    <button
                        className={`${styles.tab_btn}`}
                        onClick={() => handleButtonClick('forumHistory')}
                    >
                        Previous Activities
                    </button>
                </div>

                {/* ==================== 목적 ==================== */}

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>Purpose</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list}>
                        {pageid === '1' &&
                            <>
                                <li>To consult with members of CAETS (International Council of Academies of Engineering and Technological Sciences) on industrial and technological agendas of each country and strengthen leadership in the global engineering and technology community</li>
                            </>
                        }
                        {pageid === '2' &&
                            <>
                                <li>To enhance national competitiveness and utilization of economic opportunities by sharing technology trends and establishing a sustainable cooperation system among Korea, China and Japan</li>
                                <li>To discover promising technologies and prepare joint countermeasures through trilateral cooperation between Korea, China and Japan</li>
                            </>
                        }
                        {pageid === '3' &&
                            <>
                                <li>
                                    To identify trends in technological innovation in the energy sector of Korea and Australia and explore areas for research and industrial collaboration, focusing on reviewing the technological status and major issues in the energy field, particularly the hydrogen industry, which is a common interest for both Australia, an energy powerhouse, and Korea, a leader in advanced industrial manufacturing
                                </li>
                            </>
                        }
                        {pageid === '4' &&
                            <>
                                <li>To build long-term ASEAN-Korea partnerships by leveraging the networks of NAEK and AAET (ASEAN Academy of Engineering and Technology)</li>
                                <li>To establish and operate a consultative group of high-level industry/engineering experts from Korea and ASEAN and promote industrial cooperation</li>
                            </>
                        }
                        {pageid === '5' &&
                            <>
                                <li>To strengthen cooperative network between Korea and China's engineering leaders by sharing technology status and strategies in areas of common interest.</li>
                            </>
                        }
                        {pageid === '6' &&
                            <>
                                <li>To promote practical industrial development and market growth through policy, technology, and business exchanges between Korea and the UK in key industrial sectors</li>
                            </>
                        }
                        {pageid === '7' &&
                            <>
                                <li>To identify innovation trends and explore areas for research and industrial cooperation between Korea and India</li>
                            </>
                        }
                        </ul>
                    </div>
                </div>

                {/* ==================== 내용 ==================== */}

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>Impact</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_2}>
                        {pageid === '1' &&
                            <>
                                <li>Building a network with CAETS members and enhancing the identity as a national engineering academy</li>
                                <li>Discussion of international agendas in the field of engineering and the current state of technology and challenges in each country</li>
                            </>
                        }
                        {pageid === '2' &&
                            <>
                                <li>Strengthening competitiveness in future industrial technologies through a continuous non-governmental knowledge network in East Asia</li>
                            </>
                        }
                        {pageid === '3' &&
                            <>
                                <li>To create synergy in the new energy industry through collaboration between Korea, a technology-rich country, and Australia, a resource-rich country, by sharing each nation’s current technological status.</li>
                                <li>To seek opportunities for the creation of new markets by sharing technology development and commercialization cases from companies, universities, and research institutes in Korea and Australia.</li>
                                <li>To serve as a platform for cooperation with Australia, deriving business models in fields such as energy production, transportation, and utilization.</li>
                            </>
                        }
                        {pageid === '4' &&
                            <>
                                <li>Identifying opportunities for collaboration and strengthening policy proposals through technical cooperation and policy exchanges between ASEAN and Korea's senior-level experts</li>
                                <li>Facilitation of mutual market access and global networking through technical and business exchanges between ASEAN and Korean companies</li>
                            </>
                        }
                        {pageid === '5' &&
                            <>
                                <li>Driving strategies and insights to become a global technology powerhouse by understanding the state of technology and R&D in each country in key engineering and technology sectors</li>
                                <li>Strengthening industrial cooperation through technology and business exchanges between Korean and Chinese companies</li>
                            </>
                        }
                        {pageid === '6' &&
                            <>
                                <li>Building mutually beneficial business partnerships and value chains between the two countries through multi-faceted exchanges in core technology areas</li>
                            </>
                        }
                        {pageid === '7' &&
                            <>
                                <li>Formulation of informed policies and strategies by reviewing the current technology status and major issues in areas of interest to both countries</li>
                                <li>Convergence of various fields such as advanced materials, aerospace engineering, telecommunications, biomedical sciences, defense, environmental engineering and more</li>
                            </>
                        }
                        </ul>
                    </div>
                </div>

                {/* ==================== 활동 내역 ==================== */}

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>Activities</span>
                    </div>
                    <div className={styles.cont_box}>
                        <table className={styles.cont_table}>
                            <tbody>
                                {pageid === '1' &&
                                    <>
                                        <tr>
                                            <th>Symposium</th>
                                            <td>
                                                <span>The Symposium is hosted by 33 member academies in rotation (Korea hosted in 2020), with a new agenda selected each year under the main theme “'Engineering for a Better World”, and a statement reflecting the outcomes of the Symposium is published.</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Council Meeting</th>
                                            <td>
                                                <span>In the presence of all 33 member academy executives, the CAETS Secretariat’s financial audit report for the previous year is approved and the current financial status is reviewed. The host country and agenda for the next year are selected and board members are appointed.</span> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>CAETS Energy Committee</th>
                                            <td>
                                                <span>As the most continuous and active working group of CAETS, the Committee involves more than 20 countries and publishes a biennial report comparing the energy policies and status of each country.</span> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Working Group</th>
                                            <td>
                                                <span className={styles.p_b_10}>Prior to the symposium, CAETS members participate in small groups on topics of their choice.</span> 
                                                <span>* Topics: Communications, Energy, Sustainable Development Goal, Diversity, Education</span> 
                                            </td>
                                        </tr>
                                    </>
                                }
                                {pageid === '2' &&
                                    <>
                                        <tr>
                                            <th>Symposium</th>
                                            <td>
                                                <span>An annual symposium is held in rotation among Korea, China and Japan, where technology trends related to the topics are shared.</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Roundtable Meeting</th>
                                            <td>
                                                <span>Trilateral initiatives and cooperation plans between Korea, China and Japan are discussed.</span> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Industrial Visits</th>
                                            <td>
                                                <span>Visits to companies, research institutes and universities are organized in conjunction with the symposium.</span> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Survey</th>
                                            <td>
                                                <span>An annual survey on the perceptions of trilateral technical cooperation is conducted to share perceptions on key issues and prepare joint responses.</span> 
                                            </td>
                                        </tr>
                                    </>
                                }
                                {pageid === '3' &&
                                    <>
                                        <tr>
                                            <th>Symposium</th>
                                            <td>
                                                <span>It is held on a rotating basis, sharing technology trends related to the topic. </span>
                                            </td>
                                        </tr>
                                    </>
                                }
                                {pageid === '4' &&
                                    <>
                                        <tr>
                                            <th>ASEAN-Korea Engineering Forum</th>
                                            <td>
                                                <span>An annual forum is held on a rotational basis with AAET. (4 times in ASEAN, 1 time in Korea)</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>ASEAN-Korea Working Group</th>
                                            <td>
                                                <span>Working group activities are organized based on mutual demand to share the current state of technology, R&D, and policy in each country and explore opportunities for collaboration.</span>
                                            </td>
                                        </tr>
                                    </>
                                }
                                {pageid === '5' &&
                                    <>
                                        <tr>
                                            <th>China-Korea Forum</th>
                                            <td>
                                                <span>NAEK and CAE (Chinese Academy of Engineering) have agreed to organize	the “China-Korea Development Forum on Engineering Sciences and Technology” annually starting in 2023. The first forum was held in July 2023 in Hangzhou, China, with the theme of "AI Technology and Application".</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Industrial Visits</th>
                                            <td>
                                                <span>Opportunities for bilateral technical and industrial cooperation are explored through site visits and exchanges with key local industries.</span>
                                            </td>
                                        </tr>
                                    </>
                                }
                                {pageid === '6' &&
                                    <>
                                        <tr>
                                            <th>Korea-UK Policy and Technology Forum on Net Zero</th>
                                            <td>
                                                <span>An annual policy and technology forum is organized to strengthen cooperation on clean energy (offshore wind, nuclear, etc.) between the two countries.</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Business Meetings</th>
                                            <td>
                                                <span>Meetings between forum attendees and relevant companies/organizations are facilitated to help build long-term cooperation networks and market entry.</span>
                                            </td>
                                        </tr>
                                    </>
                                }
                                {pageid === '7' &&
                                    <>
                                        <tr>
                                            <th>Workshop</th>
                                            <td>
                                                <span>An annual workshop is organized with the consultation of International Relations Committee and in coordination with INAE (Indian National Academy of Engineering). Following the workshop, a joint statement from the two academies is released.</span>
                                            </td>
                                        </tr>
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>


                <div className={`${styles.homepage_btn_box} ${styles.m_b_40}`}>
                    {pageid === '1' &&
                        <>
                            <a href="https://www.newcaets.org/" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>CAETS</span>
                                <span>Link to CAETS website</span>
                            </a>
                        </>
                    }
                    {pageid === '2' &&
                        <>
                            <a href="https://en.cae.cn/cae/html/en/index.html" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>CAE</span>
                                <span>Link to CAE website</span>
                            </a>
                            <a href="https://www.eaj.or.jp/" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>EAJ</span>
                                <span>Link to EAJ website</span>
                            </a>
                        </>
                    }
                    {pageid === '3' &&
                        <>
                            <a href="https://www.atse.org.au/" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>ATSE</span>
                                <span>Link to ATSE website</span>
                            </a>
                            
                        </>
                    }
                    {pageid === '4' &&
                        <>
                            <a href="https://aaet-asean.org/" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>AAET</span>
                                <span>Link to AAET website</span>
                            </a>
                            
                        </>
                    }
                    {pageid === '5' &&
                        <>
                            <a href="https://en.cae.cn/cae/html/en/index.html" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>CAE</span>
                                <span>Link to CAE website</span>
                            </a>
                            
                        </>
                    }
                    {pageid === '6' &&
                        <>
                            <a href="https://raeng.org.uk/" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>RAEng</span>
                                <span>Link to RAEng website</span>
                            </a>
                            
                        </>
                    }
                    {pageid === '7' &&
                        <>
                            <a href="https://www.inae.in/" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>INAE</span>
                                <span>Link to INAE website</span>
                            </a>
                            
                        </>
                    }
                </div>

                <div className={`${styles.tab_btn_box} ${styles.m_t_b_100}`} ref={forumHistoryRef}>
                    <button
                        className={`${styles.tab_btn}`}
                        onClick={() => handleButtonClick('overview')}
                    >
                        Overview
                    </button>
                    <button
                        className={`${styles.tab_btn} ${styles.active}`}
                        onClick={() => handleButtonClick('forumHistory')}
                    >
                        Previous Activities
                    </button>
                </div>
                {returnTableList}
            </div>
        </>
    );
}

export default Caets;
